import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
	Button,
	Content,
	Input,
	Loading,
	RegistrationHeader
} from "components";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { triggerAnalyticsEvent } from "services/firebase";
import { requestPasswordReset } from "services/auth";
import styles from "./styles.module.scss";

const ResetPasswordSchema = Yup.object().shape({
	email: Yup.string().required("Campo obrigatório")
});

export default function ResetPasswordPage() {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [requested, setRequested] = useState<boolean>(false);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: ""
		},
		validationSchema: ResetPasswordSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			if (requested) {
				setSubmitting(false);
				navigate("/login");
				return;
			}

			await requestPasswordReset(values.email)
				.then(async (result) => {
					setSubmitting(false);
					setRequested(true);
				})
				.catch((error) => {
					setSubmitting(false);
					toast.error("Algo saiu errado, tente novamente", {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light"
					});
				});
		}
	});

	useEffect(() => {
		triggerAnalyticsEvent("cp-open-reset-password");
	}, []);

	return (
		<div className={styles.container}>
			<RegistrationHeader
				name=""
				description="Simples, rápido e gratuito. Cadastre-se agora e amplie suas oportunidades de trabalho."
			/>
			<form onSubmit={formik.handleSubmit}>
				{requested ? (
					<Content className={styles.registerContainer}>
						<div className="mx-auto">
							<span>
								Um link foi enviado para o e-mail cadastrado para confirmar o
								acesso. Você deve recebê-lo em alguns momentos.
							</span>
						</div>

						<Button
							type="submit"
							variant="outline"
							disabled={!formik.isValid || !formik.dirty || submitting}
						>
							{submitting ? <Loading size="small" /> : "Entrar na conta"}
						</Button>
					</Content>
				) : (
					<Content className={styles.registerContainer}>
						<div className="mx-auto">
							<span>Preencha o e-mail cadastrado</span>
						</div>
						<Input
							id="email"
							name="email"
							placeholder="Digite seu e-mail aqui"
							label="E-MAIL"
							value={formik.values.email || ""}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && formik.errors.email}
							errorMessage={formik.errors.email}
							type="email"
						/>
						<Button
							type="submit"
							disabled={!formik.isValid || !formik.dirty || submitting}
						>
							{submitting ? <Loading size="small" /> : "Recuperar senha"}
						</Button>

						<div className="mx-auto">
							<Button
								type="button"
								variant="text"
								onClick={() => navigate("/login")}
							>
								Entrar na conta
							</Button>
						</div>
					</Content>
				)}
			</form>
		</div>
	);
}
