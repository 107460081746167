import { Card } from "components";

import { ProviderRequestListProps } from "./types";

import styles from "./styles.module.scss";

export default function DashboardRequestList({
	title,
	items
}: ProviderRequestListProps): JSX.Element {
	return (
		<section className={styles.requestListWrapper}>
			<h2 className={styles.requestListTitle}>{title}</h2>
			<div className={styles.requestListContent}>
				{items.map((budgetRequest) => (
					<Card
						key={budgetRequest.id}
						title={`Orçamento ${budgetRequest.id.slice(-6)}`}
						message={budgetRequest.service}
						status={
							budgetRequest.has_unread_messages
								? "Nova mensagem"
								: budgetRequest.status
						}
						variant={budgetRequest.has_unread_messages ? "primary" : "neutral"}
						goTo={`/budget-request/${budgetRequest.magic_link}`}
					/>
				))}
			</div>
		</section>
	);
}
