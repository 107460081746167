import { useNavigate } from "react-router-dom";
import {
	IcoAngleLeft,
	IcoExternalLink,
	IcoLink,
	LogoTrigo
} from "assets/icons";
import { Button, Input } from "components";
import { triggerAnalyticsEvent } from "services/firebase";

import PessoaCelularDesktop from "assets/images/landing/desktop/pessoa-usando-celular.png";
import PessoaCelularMobile from "assets/images/landing/mobile/pessoa-usando-celular.png";

import PedidoOrcamentoDesktop from "assets/images/landing/desktop/app-pedido-orcamento.png";
import PedidoOrcamentoMobile from "assets/images/landing/mobile/app-pedido-orcamento.png";

import PessoasConversaDesktop from "assets/images/landing/desktop/pessoas-celular.png";
import PessoasConversaMobile from "assets/images/landing/mobile/pessoas-celular.png";

import Ambiente from "assets/images/landing/serviceIcons/ambiente_e_decoracao.png";
import Eletrica from "assets/images/landing/serviceIcons/eletrica.png";
import Marcenaria from "assets/images/landing/serviceIcons/marcenaria.png";
import Hidraulica from "assets/images/landing/serviceIcons/hidraulica.png";
import Limpeza from "assets/images/landing/serviceIcons/limpeza.png";
import Gas from "assets/images/landing/serviceIcons/gas.png";

import AppleStore from "assets/images/landing/desktop/ios.png";
import AndroidStore from "assets/images/landing/desktop/android.png";

import TrigupBlack from "assets/images/landing/desktop/trigup-black.png";
import IniciativaTrigo from "assets/images/landing/desktop/iniciativa-logo.png";

import clsx from "clsx";

import { useFormik } from "formik";

import * as Yup from "yup";
import { MaskHelper, StringHelper } from "helpers";
import styles from "./styles.module.scss";

const showPartnerForm = false;

const appStoreHref = "https://apps.apple.com/us/app/trigup/id6474207846";
const playStoreHref =
	"https://play.google.com/store/apps/details?id=br.com.grupotrigo.trigo";

interface PartnerFormValues {
	name: string;
	phone: string;
}

const googlePartnerFormUrl =
	"https://docs.google.com/forms/d/e/1FAIpQLSe_VWrjgZ_v2R8Gj6b0bATrDo3rdgTiRW8f9CHXJ4nS4LtUgQ/viewform?usp=pp_url";
const getPrefilledPartnerFormHref = (values: PartnerFormValues) =>
	`${googlePartnerFormUrl}&entry.28012181=${values.name}&entry.354984236=${values.phone}`;

const lpEvents = {
	headerParticipate: "lp-header-participation",
	amFranchisee: "lp-hero-am-franchisee",
	wantPartner: "lp-hero-want-partner",
	goToPartnerForm: "lp-go-to-partner-form",
	goToAppDownload: "lp-go-to-app-download",
	partnerFormSubmit: "lp-partner-form-submit",
	partnerFormNavigate: "lp-partner-form-navigate",
	appDownloadAndroid: "lp-app-download-android",
	appDownloadIOS: "lp-app-download-ios"
};

const sectionIds = {
	partnerForm: "partner-form",
	appLinks: "app-store-links"
};

function ServiceIcon({
	label,
	src,
	id
}: Readonly<{ label: string; src: string; id: string }>): JSX.Element {
	return (
		<div className={styles.serviceIcon}>
			<img id={id} src={src} alt="" />
			<label htmlFor={id}>{label}</label>
		</div>
	);
}

const initialPartnerFormData = {
	name: "",
	phone: ""
};

function navigateToParnerForm(values?: PartnerFormValues) {
	const outerFormHref = values
		? getPrefilledPartnerFormHref(values)
		: googlePartnerFormUrl;
	const anchor = document.createElement("a");
	anchor.href = outerFormHref;
	anchor.target = "_blank";
	anchor.rel = "noreferrer";
	anchor.click();
}

function PartnerForm(): JSX.Element {
	const formik = useFormik({
		initialValues: initialPartnerFormData,
		enableReinitialize: true,
		onSubmit: navigateToParnerForm,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Necessário preencher esse campo"),
			phone: Yup.string()
				.required("Necessário preencher esse campo")
				.test("valid_phone", "Telefone inválido", (value) => {
					console.log(value);
					return StringHelper.isValidPhone(value);
				})
		})
	});

	const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
		formik;

	return (
		<form
			className={styles.textContainer}
			onSubmit={(evt) => {
				triggerAnalyticsEvent(lpEvents.partnerFormSubmit);
				handleSubmit(evt);
			}}
		>
			<h1>Seja um parceiro TrigUP</h1>
			<h2>Venha fazer parte da nossa rede de parceiros</h2>
			<Input
				containerClassName={styles.partnerFormField}
				placeholder="Nome"
				type="text"
				name="name"
				label=""
				id="name"
				value={values.name}
				onChange={handleChange}
				error={touched.name && errors.name}
				errorMessage={errors.name}
			/>
			<Input
				containerClassName={styles.partnerFormField}
				placeholder="Whatsapp"
				type="tel"
				name="phone"
				label=""
				id="phone"
				value={MaskHelper.phone(values.phone) ?? ""}
				onChange={(evt) =>
					setFieldValue("phone", MaskHelper.phone(evt.target.value))
				}
				error={touched.phone && errors.phone}
				errorMessage={errors.phone}
			/>
			<Button
				type="submit"
				variant="primary"
				className={styles.partnerActionButton}
			>
				<span>Quero prestar serviços</span>
				<IcoExternalLink color="white" width="24" height="24" />
			</Button>
		</form>
	);
}

function PartnerText(): JSX.Element {
	const navigate = useNavigate();

	return (
		<div className={styles.textContainer}>
			<h1>Seja um parceiro TrigUP</h1>
			<h2>Venha fazer parte da nossa rede de parceiros</h2>
			<Button
				type="submit"
				variant="primary"
				className={styles.partnerActionButton}
				onClick={() => {
					triggerAnalyticsEvent(lpEvents.partnerFormNavigate);
					navigate("/signin");
				}}
			>
				<span>Quero prestar serviços</span>
				<IcoExternalLink width="24" height="24" />
			</Button>
		</div>
	);
}

export default function LandingPage(): JSX.Element {
	const scrollTo = (elementId: string) => {
		const targetElement = document.getElementById(elementId);
		window.scrollTo({ behavior: "smooth", top: targetElement?.offsetTop });
	};
	const navigate = useNavigate();

	const goToAppDownload = () => scrollTo(sectionIds.appLinks);
	const goToPartnerForm = () => scrollTo(sectionIds.partnerForm);

	return (
		<div>
			<header className={styles.landingHeader}>
				<div className={styles.headerLogo}>
					<LogoTrigo />
				</div>
				<Button
					variant="text"
					onClick={() => {
						triggerAnalyticsEvent(lpEvents.headerParticipate);
						goToPartnerForm();
					}}
				>
					<span>Quero participar</span>
				</Button>
			</header>
			<main className={styles.landingPageWrapper}>
				<section className={styles.hero}>
					<div className={styles.heroContent}>
						<LogoTrigo />
						<div className={styles.heroText}>
							<h1>Uma plataforma do grupo trigo</h1>
							<h2>
								para conectar franqueados
								<br />
								com os melhores parceiros de serviços
							</h2>
						</div>
						<div className={styles.heroOptions}>
							<Button
								variant="primary"
								onClick={() => {
									triggerAnalyticsEvent(lpEvents.amFranchisee);
									goToAppDownload();
								}}
							>
								<span>Sou Franqueado Grupo Trigo</span>
							</Button>
							<Button
								variant="outline"
								onClick={() => {
									triggerAnalyticsEvent(lpEvents.wantPartner);
									goToPartnerForm();
								}}
							>
								<span>Quero ser um Parceiro</span>
							</Button>
						</div>
					</div>
				</section>

				<section className={styles.reverseSplit}>
					<div className={styles.imageContainer}>
						<img
							className={clsx(styles.desktopImage)}
							src={PessoaCelularDesktop}
							alt="Uma franqueada utilizando o app"
						/>
						<img
							className={styles.mobileImage}
							src={PessoaCelularMobile}
							alt="Uma franqueada utilizando o app"
						/>
					</div>
					<div className={styles.textContainer}>
						<div className={styles.smallLogo}>
							<LogoTrigo />
						</div>
						<h1>Mais uma facilidade para você gerenciar o seu negócio</h1>
						<h2>
							O Grupo Trigo tem o compromisso de oferecer todo apoio necessário
							para o franqueado administrar sua loja, seja antes da inauguração
							ou no seu dia-a-dia.
						</h2>
						<p>
							Com o <strong>TrigUp</strong> você encontra os melhores
							profissionais para manutenções de equipamentos, instalações,
							suporte, treinamentos e muito mais, podendo pedir orçamento direto
							pela plataforma.
						</p>
						<p>Rápido e na palma da sua mão.</p>
						<Button
							variant="outline"
							onClick={() => {
								triggerAnalyticsEvent(lpEvents.goToAppDownload);
								goToAppDownload();
							}}
						>
							Baixe agora o nosso app
						</Button>
					</div>
				</section>

				<section className={styles.splitContent}>
					<div className={styles.imageContainer}>
						<img
							className={styles.desktopImage}
							src={PedidoOrcamentoDesktop}
							alt="Um fornecedor realizando manutenção de uma torneira. Ao lado é exibida uma negociação de orçamento pelo app."
						/>
						<img
							className={styles.mobileImage}
							src={PedidoOrcamentoMobile}
							alt="Um fornecedor realizando manutenção de uma torneira. Ao lado é exibida uma negociação de orçamento pelo app."
						/>
					</div>
					<div className={styles.textContainer}>
						<h1>Parceiros especialistas</h1>
						<h2>
							Sabemos que o sucesso de qualquer negócio depende de parceiros de
							confiança.
						</h2>
						<p>
							Por isso, o Grupo Trigo conta com uma rede de parceiros
							especialistas em serviços que atendem às necessidades de nossas
							franquias. Qualidade e garantia para o franqueado e uma clientela
							fidelizada para o parceiro de serviço.
						</p>
						<Button
							variant="outline"
							onClick={() => {
								triggerAnalyticsEvent(lpEvents.goToPartnerForm);
								navigate("/signin");
							}}
						>
							Faça cadastro como Parceiro
						</Button>
					</div>
				</section>

				<section className={styles.iconRow}>
					<ServiceIcon id="eletrica" src={Eletrica} label="Elétrica" />
					<ServiceIcon id="hidraulica" src={Hidraulica} label="Hidráulica" />
					<ServiceIcon id="ambiente" src={Ambiente} label="Ambiente" />
					<ServiceIcon id="gas" src={Gas} label="Gás" />
					<ServiceIcon id="limpeza" src={Limpeza} label="Limpeza" />
					<ServiceIcon id="marcenaria" src={Marcenaria} label="Marcenaria" />
					<div className={styles.fancy}>e muito mais...</div>
				</section>

				<section className={styles.partnerForm} id={sectionIds.partnerForm}>
					{showPartnerForm ? <PartnerForm /> : <PartnerText />}
					<div className={styles.imageContainer}>
						<img
							className={styles.desktopImage}
							src={PessoasConversaDesktop}
							alt="Prestador e solicitante conversando; Ao lado, a tela de convite do app TrigUP"
						/>
						<img
							className={styles.mobileImage}
							src={PessoasConversaMobile}
							alt="Prestador e solicitante conversando; Ao lado, a tela de convite do app TrigUP"
						/>
					</div>
				</section>

				<section className={styles.appStoreRow} id={sectionIds.appLinks}>
					<div className={styles.textContainer}>
						<h1>Franqueado</h1>
						<h2> Baixe agora seu mais novo assistente de trabalho</h2>
					</div>
					<div className={styles.appStoreCard}>
						<img src={AppleStore} alt="Logo da App store para iOS" />
						<a target="_blank" href={appStoreHref} rel="noreferrer">
							<Button
								variant="primary"
								onClick={() => {
									triggerAnalyticsEvent(lpEvents.appDownloadIOS);
								}}
							>
								<span>Baixar no iOS</span>
							</Button>
						</a>
					</div>
					<div className={styles.appStoreCard}>
						<img src={AndroidStore} alt="Logo da Play store no Android" />
						<a target="_blank" href={playStoreHref} rel="noreferrer">
							<Button
								variant="primary"
								onClick={() => {
									triggerAnalyticsEvent(lpEvents.appDownloadAndroid);
								}}
							>
								<span>Baixar no Android</span>
							</Button>
						</a>
					</div>
				</section>
			</main>
			<footer className={styles.landingFooter}>
				<div className={styles.footerLogo}>
					<img src={TrigupBlack} alt="TrigUp" />
				</div>
				<div className={styles.footerLogo}>
					<img src={IniciativaTrigo} alt="Uma iniciativa Trigo" />
				</div>
			</footer>
		</div>
	);
}
