import clsx from "clsx";

import { IcoClose, LogoTrigo } from "assets/icons";
import TopBar from "components/TopBar";
import { SideBarProps } from "./types";

import styles from "./styles.module.scss";

export default function SideBar({ children, show, toggle }: SideBarProps) {
	return (
		<>
			<div
				onClick={() => toggle()}
				className={clsx(styles.sideMenuBackdrop, {
					[styles.sideMenuVisible]: show
				})}
			/>
			<div
				className={clsx(styles.sideMenuWrapper, {
					[styles.sideMenuVisible]: show
				})}
			>
				<div className={styles.sideMenuHeader}>
					<TopBar>
						<LogoTrigo height="40" />
						<span className={styles.closeMenuButton} onClick={() => toggle()}>
							<IcoClose width="32" height="32" color="#773FAF" />
						</span>
					</TopBar>
				</div>
				<div className={styles.sideMenuContent}>{children}</div>
			</div>
		</>
	);
}
