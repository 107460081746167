import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

import {
	BudgetRequestPage,
	FeedbackPage,
	LandingPage,
	LoginPage,
	OfferedServicesPage,
	PersonalInformationPage,
	PrivacyPolicyPage,
	ProviderDashboard,
	ResetPasswordPage,
	SignInPage,
	SuperPerfilPage
} from "pages";

import { ProviderLayout, SingleLayout, TwoColumnsLayout } from "layouts";
import BudgetRequestExpiredPage from "pages/budget-request/budget-request-expired";
import BudgetRequestMessageSentPage from "pages/budget-request/budget-request-message-sent";
import BudgetRequestRefusedPage from "pages/budget-request/budget-request-refused";
import PasswordRecoveryPage from "pages/password-recovery";
import SuccessPasswordResetPage from "pages/password-recovery/success";
import { RootState } from "store";

function Routers() {
	const { pathname } = useLocation();

	const { logged_user } = useSelector((state: RootState) => state.tokens);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	return (
		<Routes>
			{logged_user ? (
				<Route path="" element={<ProviderLayout />}>
					<Route path="/" element={<ProviderDashboard />} />
					<Route path="/reset-password/" element={<ResetPasswordPage />} />

					<Route path="/cadastro" element={<SingleLayout />}>
						<Route
							path="informacoes-pessoais"
							element={<PersonalInformationPage />}
						/>
						<Route path="tipos-de-servicos" element={<OfferedServicesPage />} />
						<Route path="super-perfil" element={<SuperPerfilPage />} />
						<Route path="feedback" element={<FeedbackPage />} />
					</Route>

					<Route path="/budget-request" element={<SingleLayout />}>
						<Route path=":budgetRequestId" element={<BudgetRequestPage />} />
						<Route
							path=":budgetRequestId/sent"
							element={<BudgetRequestMessageSentPage />}
						/>
						<Route
							path=":budgetRequestId/rejected"
							element={<BudgetRequestRefusedPage />}
						/>
						<Route
							path=":budgetRequestId/expired"
							element={<BudgetRequestExpiredPage />}
						/>
					</Route>
				</Route>
			) : (
				<>
					<Route path="/" element={<LandingPage />} />
					<Route path="" element={<TwoColumnsLayout />}>
						<Route path="/signin" element={<SignInPage />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/reset-password/" element={<ResetPasswordPage />} />
					</Route>

					<Route path="/cadastro" element={<SingleLayout />}>
						<Route
							path="informacoes-pessoais"
							element={<PersonalInformationPage />}
						/>
						<Route path="tipos-de-servicos" element={<OfferedServicesPage />} />
						<Route path="super-perfil" element={<SuperPerfilPage />} />
						<Route path="feedback" element={<FeedbackPage />} />
					</Route>
					<Route
						path="/password-recovery/:token"
						element={<PasswordRecoveryPage />}
					/>
					<Route
						path="/recovery-success"
						element={<SuccessPasswordResetPage />}
					/>
				</>
			)}
			<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

			<Route path="/budget-request" element={<SingleLayout />}>
				<Route path=":budgetRequestId" element={<BudgetRequestPage />} />
				<Route
					path=":budgetRequestId/sent"
					element={<BudgetRequestMessageSentPage />}
				/>
				<Route
					path=":budgetRequestId/rejected"
					element={<BudgetRequestRefusedPage />}
				/>
				<Route
					path=":budgetRequestId/expired"
					element={<BudgetRequestExpiredPage />}
				/>
			</Route>
		</Routes>
	);
}

export default Routers;
