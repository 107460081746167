import { IcoMessage } from "assets/icons";

import clsx from "clsx";
import styles from "./styles.module.scss";

export default function DashboardEmptyContent(): JSX.Element {
	return (
		<div className={clsx(styles.emptyDashboardWrapper)}>
			<IcoMessage width="80" height="80" />
			<div className={styles.emptyDashboardMessage}>
				<h2 className={styles.messageTitle}>Ainda não há mensagens aqui</h2>
				<p className={styles.messageText}>
					Fique atento, em breve os franqueados do Grupo Trigo podem entrar em
					contato para um novo orçamento.
				</p>
			</div>
		</div>
	);
}
