import clsx from "clsx";

import { SideBarItemProps } from "./types";

import styles from "./styles.module.scss";

export default function SideBarItem({
	children,
	clickable,
	onClick
}: SideBarItemProps) {
	return (
		<div
			className={clsx(styles.sideBarItem, {
				[styles.clickable]: clickable
			})}
			onClick={() => {
				if (clickable && onClick) {
					onClick();
				}
			}}
		>
			{children}
		</div>
	);
}
