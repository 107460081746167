import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { IcoEnter } from "assets/icons";
import LogoTrigupAvatar from "assets/icons/logo-trigup-avatar";
import { CardProps } from "./types";

import styles from "./styles.module.scss";

export default function Card({
	variant = "primary",
	title,
	message,
	avatar = <LogoTrigupAvatar />,
	status,
	goTo
}: CardProps): JSX.Element {
	const navigate = useNavigate();

	return (
		<div
			className={clsx([styles.cardContainer, styles[variant]], {
				[styles.clickable]: goTo
			})}
			onClick={() => {
				if (goTo) {
					navigate(goTo);
				}
			}}
		>
			<div className={styles.cardContent}>
				<div className={styles.content}>
					<div className={styles.avatar}>{avatar}</div>
					<div className={styles.label}>
						<div>
							<p className={styles.cardTitle}>{title}</p>
							<p className={styles.cardMessage}>{message}</p>
						</div>
						<p className={styles.cardStatus}>{status}</p>
					</div>
				</div>
				{goTo && (
					<div className={styles.goTo}>
						{/* eslint-disable-next-line */}
						<a href={goTo}>
							<IcoEnter />
						</a>
					</div>
				)}
			</div>
		</div>
	);
}
