import { IconProps } from "./types";

export default function IcoChat({
	color = "#773FAF",
	width = "32",
	height = "32"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_5804_6161)">
				<path
					d="M26.667 2.6665H5.33366C3.86699 2.6665 2.66699 3.8665 2.66699 5.33317V29.3332L8.00033 23.9998H26.667C28.1337 23.9998 29.3337 22.7998 29.3337 21.3332V5.33317C29.3337 3.8665 28.1337 2.6665 26.667 2.6665Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_5804_6161">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
