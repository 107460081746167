import { IconProps } from "./types";

export default function IcoLogout({
	color = "#773FAF",
	width = "32",
	height = "32"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.4333 21.7C20.1889 21.4111 20.0667 21.0831 20.0667 20.716C20.0667 20.3498 20.1889 20.0444 20.4333 19.8L22.9 17.3333H13.3333C12.9556 17.3333 12.6391 17.2053 12.384 16.9493C12.128 16.6942 12 16.3778 12 16C12 15.6222 12.128 15.3053 12.384 15.0493C12.6391 14.7942 12.9556 14.6667 13.3333 14.6667H22.9L20.4333 12.2C20.1667 11.9333 20.0333 11.6169 20.0333 11.2507C20.0333 10.8836 20.1667 10.5667 20.4333 10.3C20.6778 10.0333 20.9836 9.9 21.3507 9.9C21.7169 9.9 22.0222 10.0222 22.2667 10.2667L27.0667 15.0667C27.2 15.2 27.2947 15.3444 27.3507 15.5C27.4058 15.6556 27.4333 15.8222 27.4333 16C27.4333 16.1778 27.4058 16.3444 27.3507 16.5C27.2947 16.6556 27.2 16.8 27.0667 16.9333L22.2667 21.7333C21.9778 22.0222 21.6613 22.1498 21.3173 22.116C20.9724 22.0831 20.6778 21.9444 20.4333 21.7ZM6.66667 28C5.93333 28 5.30533 27.7391 4.78267 27.2173C4.26089 26.6947 4 26.0667 4 25.3333V6.66667C4 5.93333 4.26089 5.30533 4.78267 4.78267C5.30533 4.26089 5.93333 4 6.66667 4H14.6667C15.0444 4 15.3613 4.12756 15.6173 4.38267C15.8724 4.63867 16 4.95556 16 5.33333C16 5.71111 15.8724 6.02756 15.6173 6.28267C15.3613 6.53867 15.0444 6.66667 14.6667 6.66667H6.66667V25.3333H14.6667C15.0444 25.3333 15.3613 25.4613 15.6173 25.7173C15.8724 25.9724 16 26.2889 16 26.6667C16 27.0444 15.8724 27.3609 15.6173 27.616C15.3613 27.872 15.0444 28 14.6667 28H6.66667Z"
				fill={color}
			/>
		</svg>
	);
}
