import { IconProps } from "./types";

export default function IcoUser({
	color = "#773FAF",
	width = "32",
	height = "32"
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_5804_4482)">
				<path
					d="M16.0003 2.6665C8.64033 2.6665 2.66699 8.63984 2.66699 15.9998C2.66699 23.3598 8.64033 29.3332 16.0003 29.3332C23.3603 29.3332 29.3337 23.3598 29.3337 15.9998C29.3337 8.63984 23.3603 2.6665 16.0003 2.6665ZM16.0003 7.99984C18.5737 7.99984 20.667 10.0932 20.667 12.6665C20.667 15.2398 18.5737 17.3332 16.0003 17.3332C13.427 17.3332 11.3337 15.2398 11.3337 12.6665C11.3337 10.0932 13.427 7.99984 16.0003 7.99984ZM16.0003 26.6665C13.2937 26.6665 10.0937 25.5732 7.81366 22.8265C10.067 21.0665 12.907 19.9998 16.0003 19.9998C19.0937 19.9998 21.9337 21.0665 24.187 22.8265C21.907 25.5732 18.707 26.6665 16.0003 26.6665Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_5804_4482">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
