import { baseConfig } from "config";
import { BudgetRequestState } from "store/features/budget-request/types";
import { getAccessToken } from "./auth";
import { Requester } from "./requester";

export const getBudgetRequest = async (id: string | null = "") => {
	return Requester.get({
		url: `${baseConfig.baseURL}/orcamento/${id}/`,
		success: (result): BudgetRequestState => result,
		error: (error) => {
			throw error;
		}
	});
};

export const sendMessage = async (message: string, id: string | null = "") => {
	return Requester.post({
		url: `${baseConfig.baseURL}/orcamento/${id}/`,
		body: { message, action: "send" },
		success: (result): BudgetRequestState => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const rejectBudgetRequest = async (
	message: string | null = "",
	id: string | null = ""
) => {
	return Requester.post({
		url: `${baseConfig.baseURL}/orcamento/${id}/`,
		body: { message, action: "reject" },
		success: (result): BudgetRequestState => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const getProviderBudgetRequests = async () => {
	const access_token = await getAccessToken();
	return Requester.get({
		url: `${baseConfig.baseURL}/orcamento-parceiro/`,
		token: access_token,
		tokenType: "Bearer",
		success: (result): BudgetRequestState[] => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};
